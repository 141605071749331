import { useTheme } from "@emotion/react";
import { Button } from "antd";
import { Price } from "backend/types/stripe.type";
import { Plan, PlanType } from "backend/utils/plan";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import CrossIcon from "components/icons/CrossIcon";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { usePlans } from "hooks/usePlans";
import useUser, { useUserData } from "hooks/useUser";
import { ResponsiveContext } from "lib/context";
import { getRelativePathname } from "lib/getRelativePathname";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useMemo } from "react";
import DatapointsModal from "./DatapointsModal";
import { PlanCard } from "./PlanCard";

const PlanModal = (): JSX.Element => {
  const theme = useTheme();
  const plans = usePlans();
  const user = useUserData();
  const router = useRouter();
  const { isLoading } = useUser();

  const { setShowPlanModal, setPlans, showPlanModal } =
    useContext(PlanModalContext);
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  const featurePoints = [
    <>
      <b>Unlock 40 Premium Data Points</b> to gain deeper insights into the
      trends of home prices in your market.
    </>,
    <>
      <b>Home Price Forecasts Score</b> is available for{" "}
      <b>all 50 States, 500 Metro Areas, and 30,000 ZIP Codes</b>, ensuring you
      stay ahead in any region.
    </>,
    <>
      <b>Enjoy 5 Free Market Reports per month and upto 60 per year</b>, giving
      you valuable, in-depth analysis to guide your decisions.
    </>,
    <>
      <b>Seamlessly Download Data into Excel</b> for easy access
    </>,
  ];

  useEffect(() => {
    setPlans(plans);
  }, [plans]);

  const filteredAndSortedPlans = useMemo<Price[]>(() => {
    return plans
      .filter((plan) =>
        [Plan.Premium_Monthly, Plan.Premium_Yearly].includes(plan.name),
      )
      .sort((a, b) => a.amount - b.amount);
  }, [plans]);

  return plans.length > 0 ? (
    <>
      <DatapointsModal
        open={!!router.query.showDatapointModal}
        onClose={() => router.replace(`${getRelativePathname(router)}`)}
      />
      <Modal
        overlayStyle={{ overflow: "auto" }}
        contentStyle={{
          padding: 0,
          background: "white",
          maxWidth: 555,
          width: "100%",
        }}
        isOpen={
          showPlanModal &&
          !isLoading &&
          (user?.plan === PlanType.Free ||
            user?.plan === PlanType.State_Level_Access ||
            !user?.uid)
        }
      >
        <div css={{ padding: "22px 34px" }}>
          <Flex
            onClick={() => setShowPlanModal(false)}
            align="center"
            justify="center"
            css={{
              position: "absolute",
              cursor: "pointer",
              background: "#EFEFEF",
              borderRadius: 4,
              height: 37,
              width: 37,
              top: 14,
              right: 14,
            }}
          >
            <CrossIcon />
          </Flex>
          <Flex direction="column" align="center" css={{ overflowX: "hidden" }}>
            <Text
              transform="uppercase"
              bolder
              heading
              css={{ color: theme.colors.primary }}
            >
              pricing
            </Text>
            <Text tagName="h1" align="center" fontSize="larger" bold>
              To use this premium feature, sign up for an all-access pass
            </Text>
          </Flex>
        </div>
        <div css={{ borderTop: `1px solid ${theme.colors.radioBorder}` }} />
        <Flex align="stretch">
          {filteredAndSortedPlans.map((plan, index) => (
            <React.Fragment key={plan.id}>
              <PlanCard plan={plan} />
              {index !== filteredAndSortedPlans.length - 1 && (
                <div
                  css={{
                    alignSelf: "stretch",
                    borderRight: `1px solid ${theme.colors.radioBorder}`,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Flex>
        <div css={{ borderTop: `1px solid ${theme.colors.radioBorder}` }} />
        {isTabletOrMobile && (
          <Flex
            css={{ background: theme.colors.lightBg, padding: 10 }}
            justify="center"
          >
            <Text align="center">
              Or continue as a{" "}
              <span
                onClick={() => {
                  if (!user.uid && !user.isLoading) {
                    router.push(`${getRelativePathname(router)}?type=signup`);
                    setShowPlanModal(false);
                  } else if (user?.plan === PlanType.Free || !user.plan) {
                    setShowPlanModal(false);
                  }
                }}
                css={{
                  color: theme.colors.primary,
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }}
              >
                Free User
              </span>{" "}
              with access to 8 basic data points
            </Text>
          </Flex>
        )}
        <Flex
          gap={27}
          css={{ padding: "30px 24px  " }}
          direction="column"
          align="center"
        >
          {featurePoints.map((point, index) => (
            <Flex key={index} css={{ maxWidth: 453, width: "100%" }}>
              <Image
                height={21}
                width={21}
                alt="check-icon"
                src="/check-outlined.svg"
              />

              <Text fontSize="medium" css={{ lineHeight: "normal" }}>
                {point}
              </Text>
            </Flex>
          ))}
        </Flex>

        {!isTabletOrMobile && (
          <Flex
            css={{ background: theme.colors.blue, padding: 10 }}
            justify="center"
            align="center"
            gap={theme.gap[3]}
          >
            {!user.id && (
              <Button
                type="primary"
                onClick={() => {
                  router.push(`${getRelativePathname(router)}?type=login`);
                  setShowPlanModal(false);
                }}
                css={{
                  fontWeight: theme.fontWeights.heading,
                  backgroundColor: `rgba(225,225,225,0.15)`,
                  borderColor: `rgba(225,225,225,0.15)`,
                }}
              >
                Login
              </Button>
            )}
            <Text
              align="center"
              fontSize="large"
              css={{
                color: theme.colors.white,
                fontSize: theme.fontSizes.medium,
                fontWeight: theme.fontWeights.bold,
              }}
            >
              Or continue as a{" "}
              <span
                onClick={() => {
                  if (!user.uid && !user.isLoading) {
                    router.push(`${getRelativePathname(router)}?type=signup`);
                    setShowPlanModal(false);
                  } else if (user?.plan === PlanType.Free || !user.plan) {
                    setShowPlanModal(false);
                  }
                }}
                css={{
                  fontWeight: theme.fontWeights.bold,
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }}
              >
                Free User
              </span>{" "}
              with access to 8 data points
            </Text>
          </Flex>
        )}
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default PlanModal;
