import { User } from "backend/types/user.type";
import { PlanType } from "backend/utils/plan";
import { useFirestoreSubscribe } from "hooks/useFireStoreSubscribe";
import { UserContext } from "lib/context";
import { auth } from "lib/firebase";
import parseJwt from "lib/helpers/parseJWT";
import {
  isAlreadyAuthorizedOnThisDevice,
  isMaxAuthDevicesLimitReached,
  isUnAuthorizedOnThisDevice,
  updateUser,
} from "lib/helpers/userHelpers";
import nookies from "nookies";
import { usePostHog } from "posthog-js/react";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocalStorage } from "react-use";
import { UserData } from "types/user";

export default function useUser(): Partial<UserData> {
  const [userLoading, setLoading] = useState(true);
  const [userAuth, isLoading] = useAuthState(auth);
  const [changedPlanFrom, setChangedPlanFrom] = useLocalStorage(
    "changedPlanFrom",
    null,
  );
  const posthog = usePostHog();
  if (!isLoading) {
    if (userAuth?.uid)
      nookies.set(undefined, "userId", userAuth.uid, { path: "/" });
    else nookies.set(undefined, "userId", "", { path: "/" });
  }

  const {
    data: userData,
    loading: isUserDetailLoading,
    setData: setUserData,
  } = useFirestoreSubscribe<User>(`users/${userAuth?.uid}`, !!userAuth?.uid);

  useEffect(() => {
    // isLoading indicates whether the userAuth is being loading can't refactor this as it's being used on other many places as well
    if (!isLoading && !isUserDetailLoading) {
      const to = setTimeout(() => {
        setLoading(false);
      }, 100);
      return () => clearTimeout(to);
    } else {
      setLoading(true);
    }
  }, [isLoading, isUserDetailLoading]);

  useEffect(() => {
    if (!userAuth?.uid) {
      setUserData(null);
    }
  }, [userAuth?.uid]);

  useEffect(() => {
    if (userData && userAuth?.accessToken) {
      (async () => {
        const { auth_time } = parseJwt(userAuth.accessToken);
        const currentAuthTime = parseInt(auth_time);

        if (!userData.authTimes || !userData.authTimes?.length) {
          return await updateUser(userData.id!, {
            authTimes: [currentAuthTime],
          });
        }

        if (
          isAlreadyAuthorizedOnThisDevice(userData.authTimes, currentAuthTime)
        ) {
          return;
        }

        if (!isMaxAuthDevicesLimitReached(userData.authTimes)) {
          return await updateUser(userData.id!, {
            authTimes: [...userData.authTimes, currentAuthTime],
          });
        }

        if (isUnAuthorizedOnThisDevice(userData.authTimes, currentAuthTime)) {
          return await updateUser(userData.id!, {
            authTimes: userData.authTimes.slice(1).concat([currentAuthTime]),
          });
        }
        posthog.capture("Signed out", {
          reason: "limit reached",
          currentAuthTime,
          newAuthTimes: userData.authTimes,
        });
        await signOut();
      })();
    }
  }, [userData?.id, userData?.authTimes, userAuth?.accessToken]);

  if (!userAuth?.uid) {
    return { isLoading };
  }

  const signOut = async () => {
    const { auth_time } = parseJwt(userAuth.accessToken);
    const authTime = parseInt(auth_time);
    const authTimes =
      userData?.authTimes?.filter((time) => time !== authTime) || [];
    const userId = userData?.id as string;
    userAuth.auth.signOut();
    try {
      await updateUser(userId, { authTimes });
    } catch (error) {
      console.log("couldn't update user on signout", error);
    }

    setUserData(null);
    posthog.reset();
  };

  return {
    ...userAuth,
    ...(userData || {}),
    isLoading: userLoading,
    signOut,
    changedPlanFrom,
    setChangedPlanFrom,
  };
}

// Custom hook to read  auth record and user profile doc
export function useUserData(): Partial<UserData> {
  const user = useContext(UserContext);

  const isFreeUser = user?.stripeSubscriptionStatus !== "active";
  const canAccessPremium =
    user?.plan === PlanType.Premium &&
    user?.stripeSubscriptionStatus === "active";
  const canAccessBasicOnly =
    user?.plan === PlanType.State_Level_Access &&
    user?.stripeSubscriptionStatus === "active";
  const isPlanGracePeriod =
    user.nextBillableAmount === 0 && user.plan !== PlanType.Free;

  return {
    ...user,
    isFreeUser,
    canAccessPremium,
    canAccessBasicOnly,
    isPremiumOrBasic: canAccessPremium || canAccessBasicOnly,
    isPlanGracePeriod,
  };
}
